import React from "react";
import Layout from "../components/Layout/Layout";

/**
 * 404 template
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NotFound = (props) => {
    const {pageContext} = props;
    const {layoutData, notFoundData} = pageContext;
    console.log({notFoundData});

    return <Layout data={layoutData}>
        <h1>Not Found</h1>
    </Layout>
}

export default NotFound;